import Vue from 'vue'
import Vuex from 'vuex'

import { AxiosResponse } from 'axios'
import Notifications from '@/store/notifications/notifications'
import { SearchApiRequest, SearchInstance, SearchInstances } from './searchApiRequestHelper'
import SearchInstanceStore from './searchInstance'

Vue.use(Vuex)

const getInitialState = (): SearchInstances => ({
  dataLoading: true,
  instances: [],
})

export default new Vuex.Store({
  state: getInitialState(),
  mutations: {
    setInstancesList(state: SearchInstances, data: SearchInstances) {
      state.instances = data.instances
      state.dataLoading = false
    },
  },
  actions: {
    getSearchInstancesData(context: any) {
      const instances = SearchApiRequest.getSearchInstances()

      return instances
        .then((result: AxiosResponse<SearchInstances>) => {
          if (result.status !== 200) {
            Notifications.commit('addError', 'search.errorGettingSearchInstances', { root: true })
            return
          }
          context.commit('setInstancesList', result.data)
          SearchInstanceStore.commit('assertSearchInstanceId', result.data.instances)
        })
        .catch(error => {
          Notifications.commit('addError', error, { root: true })
        })
    },
    storeSearchInstance(context: any, instance: SearchInstance) {
      const storeResponse = SearchApiRequest.storeSearchInstance(instance)

      return storeResponse
        .then((response: AxiosResponse<SearchInstance>) => {
          if (response.status !== 200) {
            throw Error('Status code is not 200')
          }
          return response
        })
        .catch(error => {
          // TODO: nieobsłużone jak coś pójdzie nie tak :/ brzydki błąd dla użytkownika
          Notifications.commit('addError', Error('search.instance.add.error'), { root: true })
          throw error
        })
    },
  },
})
