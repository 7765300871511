


















































import Vue from 'vue'
import CustomerCatalogStore from '@/store/catalog/customerCatalogs'
import SearchInstancesStore from '@/store/search/searchInstances'
import { CatalogIdentity } from '@/store/catalog/catalogApiRequest'
import { LocaleMessages } from 'vue-i18n'

interface SearchAddInstance {
  instance: {
    id: string,
    name: string,
    catalogName: string,
    catalogId: string,
  },
  selectedCatalog: string,
  catalogs: Array<{
    value: string
    text: string
    type: string
  }>
}

export default Vue.extend({
  components: {},
  data(): SearchAddInstance {
    return {
      instance: {
        id: 'Autogenerated',
        name: '',
        catalogName: '',
        catalogId: '',
      },
      selectedCatalog: '',
      catalogs: [],
    }
  },

  computed: {
    labelInstanceName(): string | LocaleMessages {
      return this.$t('search.instance.add.label.instanceName')
    },
    labelSelectCatalog(): string | LocaleMessages {
      return this.$t('search.instance.add.label.selectCatalog')
    },
    getCatalogs(): Array<{ value: string, text: string }> {
      const self = this

      self.catalogs = CustomerCatalogStore.state.catalogs.map((item: CatalogIdentity) => ({
        value: item.id,
        text: item.name,
        type: item.type,
      }))
      self.catalogs = self.catalogs.filter(catalog => catalog.type !== 'wizard_test')
      return this.catalogs
    },
  },
  mounted() {
    CustomerCatalogStore.dispatch('loadCatalogList')
  },
  methods: {
    getValidationState(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? validation.valid : null
    },
    onSubmit() {
      const catalogDescription = this.catalogs.filter(item => item.value === this.selectedCatalog).pop()

      if (!catalogDescription) {
        // TODO co jak nie znalezione?
        return
      }

      const storeResult = SearchInstancesStore.dispatch('storeSearchInstance', {
        instanceId: null,
        instanceName: this.instance.name,
        catalogId: catalogDescription.value,
        catalogName: catalogDescription.text,
      })

      storeResult
        .then(success => {
          this.$router.push({ name: 'searchInstances' })
            .catch(() => {})
          return success
        })
        .catch(() => {
          console.log('something went wrong')
        })
    },
  },
})
