import { AxiosError, AxiosResponse } from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import Notifications from '@/store/notifications/notifications'
import CatalogApiRequest, { CatalogIdentity } from './catalogApiRequest'

Vue.use(Vuex)

interface CustomerCatalogs {
  catalogs: Array<CatalogIdentity>
  dataLoading: boolean
}

export default new Vuex.Store({
  state: (): CustomerCatalogs => ({
    catalogs: [],
    dataLoading: true,
  }),

  mutations: {
    setCatalogList(state: CustomerCatalogs, catalogIdentities: Array<CatalogIdentity>) {
      state.catalogs = catalogIdentities
      state.dataLoading = false
    },
  },
  actions: {
    loadCatalogList(context: any) {
      const catalogList = CatalogApiRequest.getCatalogsList()

      return catalogList
        .then((result: AxiosResponse<Array<CatalogIdentity>>) => {
          if (result.status !== 200) {
            Notifications.commit('addError', 'catalog.errorReceivingCatalogSettings', { root: true })
            return
          }
          const catalogsList = result.data
          context.commit('setCatalogList', catalogsList)
        })
        .catch((error: AxiosError) => {
          console.log(error)
          Notifications.commit('addError', error.message, { root: true })
        })
    },
  },
})
